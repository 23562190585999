import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
import Banner from '../../../images/blog-banner.jpg'
import YouTube from 'react-youtube';
import Blog1 from '../../../images/blog/ceo.jpg'
import Play from '../../../images/ceo/play.svg'
import { FaLinkedin } from 'react-icons/fa';
import BlogForm from './BlogForm'
import { FacebookIcon, FacebookShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, LinkedinShareButton } from "react-share";






const IdeasforManagers = (props) => {
    const [modal, setModal] = useState(false);
    //   const [nestedModal, setNestedModal] = useState(false);
    //   const [closeAll, setCloseAll] = useState(false);
    const toggle = () => setModal(!modal);
    const shareUrl = 'https://www.tridentconsultinginc.com/Company/Blog/Diversity/';
    const title = 'Five Ways Diverse Organizations Hire Differently';
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const { className } = props;


    return (
        <Layout path="/blog/">
            <section className="banner_min">
                <div className="banner_section">
                    {/* <Img fluid={Banner} ></Img> */}
                    <img src={Banner} alt="img" />
                </div>

            </section>
            <section className="blog_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <div>
                                <h3 className="text_center">Ideas for Managers to engage with their teams <br />in the current remote working scenario</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md={{ size: 3, offset: 5 }}>
                            <div className="blog_heading_text">
                                <h4><span> by</span> <a href="/Company/AboutCEO">Shabana Siraj</a></h4>
                            </div>
                            <div className="blog_heading">
                                <a href="https://www.linkedin.com/in/shabanasiraj/" target="blank">
                                    <FaLinkedin size={20} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md={{ size: 3, offset: 5 }}>
                            <div className="share_blog">
                                <h5 className="share_text">Share :</h5>
                                <div className="Demo__some-network">
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <FacebookIcon size={30} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TwitterIcon size={30} round />
                                    </TwitterShareButton>
                                    <LinkedinShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <LinkedinIcon size={30} round />
                                    </LinkedinShareButton>
                                </div>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="main_card">
                                <div className="card_text">
                                    <a href onClick={toggle1}>
                                        <div className="card_button">
                                            <h4>Check out the top benefits of diversity and the statistics you need to know to build out your program.</h4>
                                        </div>
                                    </a>
                                </div>
                                <div className="card_bg">
                                    <div className="card_bg1">
                                        <a href="#" onClick={toggle1}>
                                            <div className="button">
                                                <h3>CLICK HERE</h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="blog_text">
                                <p>A year and three months since the pandemic was officially declared, countries are still recovering from the onslaught. With vaccinations increasingly administered however, life and the economy have started getting back to a ‘newer’ normal. Restrictions on businesses are easing and offices have started functioning... albeit cautiously.</p>
                                <p>Re-emerging businesses come with new learnings. Both Management and Staff have learnt the pluses and drawbacks of ‘work from home’. Both will have their own outlook on the need, safety, and economics of returning to a workplace. The crux of matching the two will fall on HR and Staffing Managers. It is early days yet though, and many may still have to deal with a predominantly remote working team, at least for another few months, if not more. </p>
                                <p>So, how can HR/Managers cope with this continuing scenario? How can they stay connected and keep their teams motivated, productive, and content? We offer here a few tips that have worked for us and our clients.</p>
                                <h6>a. Basic. Call and chat.</h6>
                                <p>Earlier, even if your official meetings were once a week, it was likely that you would run into team members at work every day, may be have an impromptu by-the-coffee-machine chat... Recreate that personal touch and check in on them as you would at such times through a call/email.</p>
                                <h6>b. Make online/work-from-home interesting.</h6>
                                <p>Set a routine where the team is pleasantly surprised to find something to look forward to when they sign in every day. An email that could carry a daily dose of humor or a trivia game of ‘Guess who in the team is ____?’. Or, you could ask team members to contribute with random images that perk up their day, or images on a theme-of-the-week basis. Sharing one of these every morning would make the whole exercise more participative. </p>
                                <h6>c. Offer an informal communication channel.</h6>
                                <p>Sure, there are a lot of messaging options on the net. But, consider offering an informal chat board. A secure space where the team could post anything from casual daily “Hi!”s, to special occasion greetings. Maybe even coordinate to help with a colleague who is under the weather…  It could be the alternative to the missing social interaction amongst colleagues! </p>
                                <h6>d. Continue to enable staff with team-building exercises that they can attend remotely.</h6>
                                <p>Organise online team building activities with/without an online event organizer. Some ideas –</p>
                                <p><strong>1. Online fitness program/challenge.</strong> Working out with an online fitness program like <a href="https://www.virginpulse.com/" target="blank">Virgin Pulse</a> and <a href="https://www.countit.com/" target="blank">Count It</a> will not only keep the team fit, but will get them working ‘together’ and encouraging each other.</p>
                                <p><strong>2. Zoom/Google Meet team-building games.</strong>Each platform you meet up on has its own features that you can use to conduct adapted on-ground team building games. For instance, the ‘breakout room’ could be used to play a ‘pass-the-secret’ (like Chinese Whispers) game, with people going into breakout rooms to pass on a sentence/tongue-twister/management tip to the next team member. Get creative or get a professional online event organizer to help.</p>
                                <p><strong>3. ‘Share the Knowledge’ – personal editions.</strong>In addition to or, as an alternative to monthly/weekly ‘Share-the-Knowledge sessions’ where different members from different departments give an insight into one aspect of what he/she does at work, run a fun ‘Share your knowledge with a short video’ program. Have different themes – ‘Cooking without fire’, ‘Learn this from my mom/wife/me’, ‘I will never again…’ and more.</p>
                                <p>Emphasize that quality of output is not the key, it’s just a sharing among friends to de-stress and may be pick up a few pointers. </p>
                                <p><strong>4. Skill-building courses/programs. </strong> Encourage staff to upskill with courses in their area of interest. Offer to pitch in with financial support/time rescheduling if possible. </p>
                                <h6>e. Encourage non-competitive, non-work-related online team meet-ups too…</h6>
                                <p>Go by your knowledge of previous habits of your team – alternatives to Fun Friday dinner outs/summer barbeques.</p>
                                <p>1. If a team member used to bring in sweet treats on Mondays, fix up with him/her and organize for a pick-up and delivery to the others on the team.</p>
                                <p>2. If you knew a few of them used to meet up for drinks on Friday nights, organise a Bring-Your-Drink online meet where they can hang out and chat while enjoying their drinks in their respective homes.</p>
                                <p>3. Sponsor a joint online viewing of an event.</p>
                                <h6>f. Stay aware and considerate.</h6>
                                <p>Through all these, keep an eye out for each of your team members. And encourage them to do the same for each other.</p>
                                <p>1. Give physical and mental health high priority. Especially right now.</p>
                                <p>2. Be personal, but, do not cross personal limits that you wouldn’t at the office.</p>
                                <p>3. Use video calling only if the recipient seems to prefer it to a voice call. Video fatigue is real.</p>
                                <p><strong>Essentially, Spread positivity.</strong> Lead with a smile and a positive attitude. Your team pick their cues from you. Remember, we may or may not be in the same perceived boat, but we are all in the same storm. We will need to work together to emerge from this stronger. Let’s make the best of this journey and look forward to working together in the office real soon... appreciating the privilege in that!</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="footer_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <h1>We'd love to hear more about your staffing challenges. Reach out to our team today.</h1>
                            <div className="footer_button">
                                <a href onClick={toggle1}>Contact Us</a>
                            </div>
                            <Modal isOpen={modal1} toggle={toggle1} className={className} className="form_modal">
                                <ModalBody >
                                    <BlogForm title="We'd love to hear more about your staffing challenges. Reach out to our team today." close={() => toggle()} />
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}
const StyledBackgroundSection = styled(IdeasforManagers)`
      width: 100%;
      background-position: bottom center;
      background-repeat: repeat-y;
      background-size: cover;
      margin: 0;
      background-color: inherit !important;
      
      `

export default StyledBackgroundSection